.image-s{
    width: 100% !important;
    height: auto !important;
    background-attachment: fixed;
}
 .carousel-control-next, .carousel-control-prev{
    display: none !important;
}
.carousel-indicators{
    display: none !important;
} 
.quotation-block-1{
    background-image: url('../../assets/images/car1.jpg');
    width: 100%;
    height: 110vh !important;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
.quotation-block-2{
    background-image: url('../../assets/images/car2.jpg');
    width: 100%;
    height: 110vh !important;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
.quotation-block-3{
    background-image: url('../../assets/images/car3.jpg');
    width: 100%;
    height: 110vh !important;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
@media screen and (max-width:830px) {
.image-s{
    width: 100% !important;
    height: 120vh !important;
}
}