.my-5{
    margin-top: 0 !important;
}
.g-0{
    margin-top: 7rem !important;
}
.lead{
    font-size: 2.5rem !important;
    margin-bottom: 2rem !important;
}
.p-3{
    padding: 2rem !important;
}
.form-control-lg{
    width: 90% !important;
    margin-bottom: 10px;
}
.btn-admin{
    width: 200px !important;
}
.mt-10{
    margin-top: 10px;
}
