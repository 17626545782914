.serv-con-span{
  font-size: 35px;
  text-align: center;
  color: #fff;
}
.ser-available-con{
    background-color: #06d6a0;
}
.phone-icon{
    margin-right: 20px;  
}
.p-no{
    border: 2px solid #fff;
    width: 95%;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: 0.5s;
	color: #06d6a0;
	background-color: #fff;
	cursor: pointer;
}
.serv-con{
    text-align: center;
    font-size: 35px;
    margin-top: 2rem !important;
}
.serv-bg-col{
    background-color: #f8f9fa;
}
.aria-l{
    text-align: center;
    border: 2px solid #06d6a0;
    border-radius: 10px;
    margin-right: 5px;
	margin-left: 5px;
    padding: 5px;
	transition: 0.5s;
	margin-top: 15px !important;
}
.aria-l{
	text-decoration: none;
	color: black;
}
.aria-l:hover{
    cursor: pointer;
	background-color: #06d6a0;
	border: 1px solid #fff;
}
.aria-l:hover{
	color: #fff;
}
.j-button{
	width: 100% !important;
}

/* our service cards */

.ico-box {
	height: 315px;
	padding: 1.563rem .9375rem .9375rem;
	text-align: center;
	position: relative;
	-webkit-transition: box-shadow linear .5s;
	-o-transition: box-shadow linear .5s;
	-webkit-transition: -webkit-box-shadow linear .5s;
	transition: -webkit-box-shadow linear .5s;
	transition: box-shadow linear .5s;
	transition: box-shadow linear .5s, -webkit-box-shadow linear .5s;
	overflow: hidden;
	letter-spacing: .02rem;
}

.ico-box:before {
	position: absolute;
	content: ' ';
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #fff;
	-webkit-transition: -webkit-transform linear .2s;
	transition: -webkit-transform linear .2s;
	-o-transition: -o-transform linear .2s;
	transition: transform linear .2s;
	transition: transform linear .2s, -webkit-transform linear .2s, -o-transform linear .2s;
	-webkit-transform: translate(101%, 0);
	-o-transform: translate(101%, 0);
	transform: translate(101%, 0);
}

.ico-box > * {
	position: relative;
	z-index: 2;
}

.ico-box .icon,.ico-box .content-title {
	color: #55565b;
}

.ico-box .icon a,.ico-box .content-title a {
	color: #55565b;
}

.ico-box:hover {
	-webkit-box-shadow: 0 .1429rem .6429rem .07143rem rgba(1, 2, 2, .1);
	box-shadow: 0 .1429rem .6429rem .07143rem rgba(1, 2, 2, .1);
}

.ico-box:hover:before {
	-webkit-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}
.ico-box:hover .icon a,.ico-box:hover .content-title a {
	color: #06d6a0;
}
.ico-box .icon {
	margin-bottom: 1.5625rem;
	color: #575757;
	font-size: 2.8125rem;
	line-height: 1;
}
.padd-serv{
	padding: 1.5rem;
}
.ico-box .des {
	color: #575757;
}
.ico-box .des p {
	margin-bottom: 0;
}
.ico-box .link-holder {
	margin-top: 1.875rem;
}
.ico-box .link-holder .link-more {
	color: #212121;
	font-size: .875rem;
}
.ico-box.dark-hover:before {
	background: #55565b;
}

.ico-box.dark-hover:hover .des,.ico-box.dark-hover:hover .link-more {
	color: #c1c5cd;
}

.ico-box.dark-hover .link-more:hover {
	color: #fff;
}

.ico-box.has-shadow {
	-webkit-box-shadow: 0 10px 40px 0 rgba(36, 83, 107, .1);
	box-shadow: 0 10px 40px 0 rgba(36, 83, 107, .1);
}
 .ico-box .icon {
	width: 6.25rem;
	height: 6.25rem;
	margin: 0 auto 1.875rem;
	text-align: center;
	border: rgba(0, 0, 0, .08) .1875rem solid;
	line-height: 6.25rem;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}

 .ico-box:hover .icon {
	background: rgba(0, 0, 0, .6);
	color: #fff;
}
.has-radius-medium {
	border-radius: .5rem;
}
.content-title a {
	color: #55565b;
}
a{
    text-decoration: none;
}
.phone-a{
	text-decoration: none;
}
.box-con-icons{
	font-size: 30px;
	margin-right: 10px;
	margin-bottom: 10px;
	color: #fff;
}
.card-body-bg-col{
	background-color: #666666;
}
.box-car-img{
	width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 0 !important;
}
.for-driv{
	font-size: 25px;
}
.cheke-icon{
	padding-right: 7px;
	font-size: 25px;
	color: #06d6a0;
}
.drive-col{
	color: #06d6a0;
}

/* for drivers image hover effect */
.bottom-space-medium-only{
	margin-bottom: 1.875rem;
}
.img-block {
	overflow: hidden;
	position: relative;
	height: auto;
}

.img-block:hover img {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transition: opacity .35s ease 0s;
	-o-transition: opacity .35s ease 0s;
	transition: opacity .35s ease 0s;
}

.img-block.image-zoom img {
	-webkit-transition: opacity .35s, -webkit-transform .35s;
	transition: opacity .35s, -webkit-transform .35s;
	-o-transition: opacity .35s, -o-transform .35s;
	transition: opacity .35s, transform .35s;
	transition: opacity .35s, transform .35s, -webkit-transform .35s, -o-transform .35s;
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.img-block.image-zoom:hover img {
	opacity: .5;
	-webkit-transform: scale(1.20);
	-o-transform: scale(1.20);
	transform: scale(1.20);
}
.shine-effect {
	position: relative;
	overflow: hidden;
}

.shine-effect:before {
	position: absolute;
	top: 0;
	left: 30px;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: .5;
	content: '';
	-webkit-transition: -webkit-transform .6s;
	transition: -webkit-transform .6s;
	-o-transition: -o-transform .6s;
	transition: transform .6s;
	transition: transform .6s, -webkit-transform .6s, -o-transform .6s;
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
	transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.shine-effect:hover:before {
	left: -30px;
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
	transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.shine-effect:hover {
	background-color: #000;
}
.img-block.image-zoom img {
	-webkit-transition: opacity .35s, -webkit-transform .35s;
	transition: opacity .35s, -webkit-transform .35s;
	-o-transition: opacity .35s, -o-transform .35s;
	transition: opacity .35s, transform .35s;
	transition: opacity .35s, transform .35s, -webkit-transform .35s, -o-transform .35s;
	-webkit-transform: scale(1.12);
	-o-transform: scale(1.12);
	transform: scale(1.12);
}
.for-drive-img{
	width: 100% !important;
	height: auto !important;
}
.ico-box:hover p {
	color: #55565b !important;
}
.ico-box:hover h3{
	color: #55565b !important;
}
.hpy-tex{
	color: #06d6a0;
}
.font-test{
	font-size: 50px;
}
.cheke-icon-con{
	padding-left: 0 !important;
}
/******* icon list styles *******/
.icon-lists ul{
	margin-left: 0 !important;
	padding-left: 0 !important;
}
.icon-lists ul li {
	list-style-type: none;
	display: block;
	position: relative;
	padding-left: 1.875rem;
	color: #55565b;
	margin: 0 0 .75rem;
}

.icon-lists ul li span {
	width: 1.875rem;
	height: 1.875rem;
	position: absolute;
	top: 0;
	left: 0;
	line-height: 2rem;
}

.icon-lists ul li h6{
	color: #55565b;
	padding-top: 5px;
	transition: 0.5s;
}

.icon-lists ul li h6:hover {
	color: #06d6a0;
}

.icon-lists.round-icons ul li {
	padding-left: 3.125rem;
}

.icon-lists.round-icons ul li span {
	border-radius: 50%;
	border: #55565b 1px solid;
	text-align: center;
}

.icon-lists.square-icons ul li {
	padding-left: 3.125rem;
}

.icon-lists.square-icons ul li span {
	border: #55565b 1px solid;
	text-align: center;
}

.icon-lists.solid-icons ul li {
	padding-left: 3.125rem;
}

.icon-lists.solid-icons ul li span {
	border: #55565b 1px solid;
	background: #55565b;
	color: #fff;
	text-align: center;
}
.cheke-box-colm{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.cash-icon{
	vertical-align: auto !important;
}