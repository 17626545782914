.pack-heading{
    font-size: 55px;
}
.select-con{
    text-align: center;
}
.element-type {
	font-size: .875rem;
	font-weight: 700;
	display: block;
	padding-top: 2.8125rem;
	padding-bottom: 2.8125rem;
}
/******* tab styles *******/

.tab-container {
	text-align: center;
}

.tab-container .nav-tabs {
	font-size: .6rem;
}

.tab-container .nav-tabs .nav-link {
	color: #55565b;
	font-weight: 700;
	text-transform: uppercase;
}

.tab-container .nav-tabs .nav-item {
	display: inline-block;
	float: none;
}

.tab-container .nav-tabs .nav-item + .nav-item {
	margin: 0 0 0 -.4125rem;
}

.tab-container .tab-button-nav {
	border-bottom: 0;
	text-align: center;
	display: block;
}

.tab-container .tab-button-nav .nav-link {
	padding: .75rem .4rem;
	border: 0;
	color: #55565b;
	border-radius: 0;
	background: #f8f8f8;
}

.tab-container .tab-button-nav .nav-link:hover,.tab-container .tab-button-nav .nav-link:focus,.tab-container .tab-button-nav .nav-link.active,.tab-container .tab-button-nav .nav-link.active:focus,.tab-container .tab-button-nav .nav-link.active:hover {
	background: #06d6a0;
	color: #fff;
}

.tab-container .tab-button-nav .open .nav-link,.tab-container .tab-button-nav .open .nav-link:focus,.tab-container .tab-button-nav .open .nav-link:hover {
	background: #06d6a0;
	color: #fff;
}

.tab-container .tab-content {
	font-size: .75rem;
	line-height: 2;
	color: #575757;
	letter-spacing: .07143rem;
}

.tab-container .tab-content p:last-child {
	margin-bottom: 0;
}

.tab-container .tab-text-nav {
	border-width: 0 0 .1429rem;
	border-color: #c1c5cd;
	display: inline-block;
}

.tab-container .tab-text-nav .nav-link {
	padding: .75rem .4rem;
	border: 0;
	color: #55565b;
	position: relative;
}

.tab-container .tab-text-nav .nav-link:before {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	content: '';
	height: .13rem;
	background: #06d6a0;
	-webkit-transition: all .3s ease-in-out .06s;
	-o-transition: all .3s ease-in-out .06s;
	transition: all .3s ease-in-out .06s;
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}
.tab-container .tab-text-nav .nav-link:hover:before,.tab-container .tab-text-nav .nav-link:focus:before,.tab-container .tab-text-nav .nav-link.active:before,.tab-container .tab-text-nav .nav-link.active:focus:before,.tab-container .tab-text-nav .nav-link.active:hover:before {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.tab-container .tab-icon-nav {
	border-width: 0;
	border-color: #c1c5cd;
	display: inline-block;
}

.tab-container .tab-icon-nav .icon {
	display: block;
	font-size: 2rem;
	margin-bottom: 2rem;
	color: #55565b;
}

.tab-container .tab-icon-nav .nav-link {
	padding: .75rem .4rem;
	border: 0;
	color: #55565b;
	position: relative;
}
.content-block {
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
}
.content-block {
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.tab-container .nav-tabs {
	font-size: .875rem;
}

.tab-container .tab-button-nav .nav-link {
	padding: .6375rem 2.813rem;
}

.tab-container .tab-text-nav .nav-link {
	padding: .6375rem 2.813rem;
}

.tab-container .tab-icon-nav .nav-link {
	padding: .75rem 2rem;
}

.tab-container.tab-secondary .nav-tabs {
	font-size: .875rem;
}

.tab-container.tab-secondary .tab-button-nav .nav-link {
	padding: .6375rem 2.813rem;
}

.tab-container.tab-secondary .tab-text-nav .nav-link {
	padding: .6375rem 2.813rem;
}

.tab-container.tab-secondary .tab-icon-nav .nav-link {
	padding: .75rem 2rem;
}
/******* caption hover full styles *******/

.caption-hover-full {
	position: relative;
}

.caption-hover-full figcaption,.caption-hover-full .figcaption {
	white-space: nowrap;
	text-align: center;
	position: absolute;
	left: 1rem;
	right: 1rem;
	top: 1rem;
	bottom: 1rem;
	color: #6e6f75;
	background: #fff;
	-webkit-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all linear .3s;
	-o-transition: all linear .3s;
	transition: all linear .3s;
}

.caption-hover-full figcaption:after,.caption-hover-full .figcaption:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 0;
	min-height: 100%;
}

.caption-hover-full figcaption > *,.caption-hover-full .figcaption > * {
	white-space: normal;
	display: inline-block;
	vertical-align: middle;
	max-width: 99%;
}

.caption-hover-full .trigger {
	position: absolute;
	top: .625rem;
	right: .625rem;
	font-size: 1.5rem;
}

.caption-hover-full .content-title {
	text-transform: capitalize;
	font-weight: 600;
	color: #6e6f75;
	position: relative;
	font-size: 1rem;
	line-height: 1.2;
}

.caption-hover-full .content-title:after {
	content: '';
	background: #d7d6d6;
	width: 2rem;
	height: .2rem;
	left: 50%;
	top: 100%;
	-webkit-transform: translate(-50%, 0);
	-o-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	position: absolute;
	margin-top: .7143rem;
}

.caption-hover-full:hover {
	cursor: pointer;
}

.caption-hover-full:hover figcaption,.caption-hover-full:hover .figcaption {
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.demo-wrapper img{
    border-radius: 5px;
}
.outs-img-sty{
	height: 270px !important;
	width: 100%;
}
.image-text{
	position: absolute;
	top:50%;
	width: 100%;
}
.image-text h2{
	color: #fff;
	font-weight: 300;
    letter-spacing: .2143rem;
	font-weight: 700;
	position: relative;
	font-size: 2rem;
	line-height: 1.2;
	text-align: center;
}
.from-to-con{
	margin-bottom: -15px !important;
	font-weight: 700;
}
.block-heading{
	margin-top: 5rem !important;
}